import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import './Footer.css';
import isMobileDevice from '../../helpers/utils';

const Footer = () => {
  const instaURL = "https://www.instagram.com/modelcitizensdance/";

  const handleButtonClick = () => {
    window.open(instaURL, '_blank');
  }
  
  return (
    <div className={`${isMobileDevice ? 'mobile' : ''} footer`}>
      <div className='icon-container'>
        <FontAwesomeIcon icon={faInstagram} className="icon" onClick={handleButtonClick}/>
      </div>
      <p className="copyright">© 2024 Model Citizens. All rights reserved.</p>
      <p className="credit">Website designed and developed by <a href="https://lizmarieportfolio.com" target="_blank" rel="noreferrer">Liz Marie</a></p>
    </div>
  );
};

export default Footer;
