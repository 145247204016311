import React from 'react';
import DancersProfile from '../../components/DancerProfile/DancerProfile';
import './Dancers.css';
import Header from '../../components/Header/Header';
import isMobileDevice from '../../helpers/utils';
import dancers from '../../helpers/quotes';

const Dancers = () => {

  return (
    <div className={`${isMobileDevice ? 'mobile' : ''} dancers-page`}>
      <Header />
      {isMobileDevice && <h4>Click on a dancer's photo to get a tatse 💋</h4>}
      <div className={`${isMobileDevice ? 'mobile' : ''} dancers`}>
        {dancers.map((dancer, index) => (
            <DancersProfile key={index} dancer={dancer} />
          ))}
      </div>
    </div>
  );
};

export default Dancers;
