import React, { useState } from 'react';
import './DancersProfile.css';
import isMobileDevice from '../../helpers/utils';

const DancersProfile = ({dancer}) => {
  const { name, quote, hometown, image } = dancer;
  const [showQuote, setShowQuote] = useState(false);

  return (
    <div className={`dancer-profile ${isMobileDevice ? 'mobile' : ''}`} onClick={() => setShowQuote(!showQuote)}>
      <div className="dancer-card">
        <div className={`overlay ${isMobileDevice && showQuote ? 'show' : ''}`}>
          <div className="center-content">
            <p>"{quote}"</p>
          </div>
          <p className="hometown">{hometown}</p>
        </div>
        <div className="dancer-image">
          <img src={image} alt={name} loading="lazy"/>
        </div>
      </div>
      <div className="name-container">
        <p>{name}</p>
      </div>
    </div>
  );
};

export default DancersProfile;
