import React from 'react'; 
import Carousel from '../../components/Carousel/Carousel';
import Header from '../../components/Header/Header';
import GroupSit from '../../assets/images/sit-group.jpg';
import Liv from '../../assets/images/liv.jpg';
import isMobileDevice from '../../helpers/utils';
import './Home.css';

const HomePage = () => {
  return (
    <div className={`${isMobileDevice ? 'mobile' : ''} home`}>
      {!isMobileDevice && <Carousel />}
      <Header />
      <div className="section first">
        <div className="section-flex">
          <h1>Ignite Your Emotions</h1>
          {isMobileDevice && (
            <img src={GroupSit} alt="Group" className="intro-img" loading="lazy"/>
          )}
          <p>
            Model Citizens is a professional dance performance group specializing
            in highlighting sexuality and creativity. Each production is designed
            to deliver a unique and unforgettable experience for our audience.
            Join us as the boundaries between art and desire fade away and the
            language of the body takes center stage.
          </p>
        </div>
        {!isMobileDevice && (
          <div className="section-image">
            <div className="aspect-ratio-container">
              <img src={GroupSit} alt="Group" loading="lazy"/>
            </div>
          </div>
        )}
      </div>
      <div className="section second">
        <div className="section-image">
          <div className="aspect-ratio-container">
            <img src={Liv} alt="Liv" loading="lazy"/>
          </div>
        </div>
        <p className="liv-bio">
          Liv Mills founded Model Citizens in 2022. She has been a dancer for 20
          years and a choreographer and instructor for 10+ years. She was born
          and raised in Bend, Oregon, and currently resides in Hell.
        </p>
      </div>
    </div>
  );
};

export default HomePage;
