import AnnieComp from '../assets/images/dancers/Anniecomp.jpg';
import AutumnComp from '../assets/images/dancers/Autumncomp.jpg';
import EmmaComp from '../assets/images/dancers/Emmacomp.jpg';
import HannahComp from '../assets/images/dancers/Hannahcomp.jpg';
import HillaryComp from '../assets/images/dancers/Hillarycomp.jpg';
import Janna from '../assets/images/dancers/Janna.jpeg';
import JessComp from '../assets/images/dancers/Jesscomp.jpg';
import Liv from '../assets/images/dancers/Liv.JPG';
import PaigeComp from '../assets/images/dancers/Paigecomp.jpg';

const dancers = [
  {
    name: 'Annie',
    quote: `Call me butter because I'm on a roll`,
    hometown: 'Bend, Oregon',
    image: AnnieComp,
  },
  {
    name: 'Autumn',
    quote: `Dont't dream it, be it`,
    hometown: 'Amherst, Massachusetts',
    image: AutumnComp,
  },
  {
    name: 'Emma',
    quote: `If you stumble, make it part of the dance`,
    hometown: 'Bend, Oregon',
    image: EmmaComp,
  },
  {
    name: 'Hannah',
    quote: `When in doubt, weird them out`,
    hometown: 'Woodinville, Washington',
    image: HannahComp,
  },
  {
    name: 'Hillary',
    quote: 'Create what sets your soul on fire!',
    hometown: 'San Jose, California',
    image: HillaryComp,
  },
  {
    name: 'Janna',
    quote: `"Dance or die" -Shakira`,
    hometown: 'Piedmont, California',
    image: Janna,
  },
  {
    name: 'Jess',
    quote: `You know`,
    hometown: 'Bay Area, California',
    image: JessComp,
  },
  {
    name: 'Liv',
    quote: `If you can't be good, be good at it`,
    hometown: 'Bend, Oregon',
    image: Liv,
  },
  {
    name: 'Paige',
    quote: `Pants Shmants`,
    hometown: 'Silverton, Oregon',
    image: PaigeComp,
  }
];

export default dancers;
