import React from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Home from './pages/Home/Home';
import Shows from './pages/Shows/Shows';
import Dancers from './pages/Dancers/Dancers';
import Contact from './pages/Contact/Contact';
import Footer from './components/Footer/Footer';
import './App.css';

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/shows" exact element={<Shows />} />
          <Route path="/dancers" exact element={<Dancers />} />
          <Route path="/contact" exact element={<Contact />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
