import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import Logo from '../../assets/images/logos/MCPlainWhite.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons'
import Lip from '../../assets/images/icons/lip.png';
import LipHover from '../../assets/images/icons/liphover.png';
import './Header.css';
import isMobileDevice from '../../helpers/utils';

const Header = () => {

  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const [isHovered, setHovered] = useState(false);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };


  return (
    <div className={`${isMobileDevice ? 'mobile' : ''} header`}>
      <div className="logo">
        <Link to="/">
          <img src={Logo} alt="Model Citizens Logo" onClick={handleClick} loading="lazy"/>
        </Link>
      </div>
      <div className="menu-icon" onClick={toggleMenu} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        {menuOpen ? (
          <FontAwesomeIcon icon={faXmark} />
        ) : (isMobileDevice ? (
          <FontAwesomeIcon icon={faBars} />
        ) : (
          <img className='lip-icon' src={isHovered ? LipHover : Lip} alt="lip icon" loading="lazy"/>
        ))}
      </div>
      <div className={menuOpen ? "menu-active" : "navbar"}>
        <NavLink exact="true" className={'navbar-item'} to="/dancers" onClick={handleClick}>
          Dancers
        </NavLink>
        <NavLink exact="true" className={'navbar-item'} to="/shows" onClick={handleClick}>
          Shows
        </NavLink>
        <NavLink exact="true" className={'navbar-item'} to="/contact" onClick={handleClick}>
          Contact
        </NavLink>
      </div>
    </div>
  );
};

export default Header;
