import React from 'react';
// import Group from '../../assets/images/sit-group.jpg';
import './Contact.css';
import Header from '../../components/Header/Header';
import DrinkVideo from '../../assets/images/drinkvideo.mp4';
import isMobileDevice from '../../helpers/utils';

const Contact = () => {
  const sendEmail = () => {
    const email = 'modelcitizensdance@gmail.com';
    const subject = 'Booking Inquiry - Event';

    const mailtoLink = `mailto:${email}?subject=${subject}`;

    window.location.href = mailtoLink;
  };

  return (
    <>
      <Header />
      <div className={`${isMobileDevice ? 'mobile' : ''} contact`}>
        <div className="contact-info">
          <p className="number">541-699-2087</p>
          <p className="email">modelcitizensdance@gmail.com</p>
          <button onClick={sendEmail}>Book us for your event!</button>
        </div>
        <div className="contact-image">
          <div className="aspect-ratio-container">
            <video
              src={DrinkVideo}
              autoPlay
              muted
              loop
              playsInline
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            ></video>
            {/* <img src={Group} alt="group" /> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
