import React from 'react';
import Logo from '../../assets/images/logos/MCBlockLogo.png';
import Arrow from '../../assets/images/icons/down-arrow.png';
import videoBg from '../../assets/images/videoBg.mp4';
import './Carousel.css';

const Carousel = () => {
  const handleArrowClick = () => {
    window.scrollTo({ top: window.innerHeight, behavior: 'smooth' });
  };

  return (
    <div className="carousel">
      <video
        src={videoBg}
        autoPlay
        muted
        loop
        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
      ></video>
      <img className="logo" src={Logo} alt="Model Citizens Logo" />
      <div className="arrow">
        <img src={Arrow} alt="arrow" onClick={handleArrowClick} />
      </div>
    </div>
  );
};

export default Carousel;
