import React from 'react';
import Poster from '../../assets/images/ELEVATE_THE_INDUSTRY.png';
import './Shows.css';
import Header from '../../components/Header/Header';
import isMobileDevice from '../../helpers/utils';

const Shows = () => {
  const eventbrite =
    'https://linktr.ee/modelcitizensdance?fbclid=PAZXh0bgNhZW0CMTEAAaaSd3R01DEFZel2o2tViMhKIWd9Y38E2C9uxk_JE5fykOE-EGGe6Um58qg_aem_mgE6AeKmzW2-s3rUvnxd2A';

  const handleButtonClick = () => {
    window.open(eventbrite, '_blank');
  };

  return (
    <>
      <Header />
      <div className={`${isMobileDevice ? 'mobile' : ''} shows`}>
        <div className="poster">
          <div className="aspect-ratio-container">
            <img src={Poster} alt="show poster" loading="lazy" />
          </div>
        </div>
        <div className="info">
          <h1>ELEVATE THE INDUSTRY</h1>
          <div className="location">
            <div className="venue">
              <h3>Volcanic</h3>
              <p>DOORS:&nbsp;&nbsp;&nbsp;6:30 PM</p>
              <p>SHOW:&nbsp;&nbsp;&nbsp;7:30 PM</p>
              <p>RUN TIME:&nbsp;&nbsp;&nbsp;1.5 HOURS</p>
            </div>
            <div className="venue">
              <h3>Silvermoon</h3>
              <p>DOORS: 7 PM</p>
              <p>SHOW: 8 PM</p>
              <p>RUN TIME: 1.5 HOURS</p>
            </div>
          </div>
          <div className="description">
            <p>
              Model Citizens presents an electrifying night of dance performance
              in an urban landscape, blending raw athleticism with sultry
              elegance. We're elevating the industry with a stage set with
              scaffolding, poles, ladders, enticing lighting, and of
              course...the hottest dancers. This show will be a feast for your
              senses with styles including pole, chair, hip-hop, floor, sexy
              contemporary, and more. With music ranging from EDM to rock &
              roll, you don't wanna miss this show.
            </p>
            <p>18+ suggested</p>
            <p>{`We always encourage dressing up or down ;)`}</p>
            <h3>TICKET INFO</h3>
            <p>
              Invite some friends and buy a table! These are the best seats in
              the house directly in front of the stage and the dancers may even
              come down to say helloooooo. Tables seat 4 - but can be adjusted,
              email @modelcitizensdance@gmail.com with questions.
            </p>
            <p>
              Standing GA is also available at all shows and still a great time!
            </p>
            <button onClick={handleButtonClick}>Tickets</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Shows;
